import Phaser from 'phaser'
import { debugDraw } from '../utils/debug';
import { createLizardAnims } from '../anims/EnemyAnims';
import { createCharacterAnims } from '../anims/CharacterAnims';
import { createChestAnims } from '../anims/TreasureAnims';
import Lizard from '../enemies/Lizard';
import '../characters/Faune';
import Faune from '../characters/Faune';
import { sceneEvents } from '../events/EventCenter';
import Chest from '../items/Chest';

export default class Game extends Phaser.Scene {

  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys | undefined;
  private faune!: Faune;
  private knives!: Phaser.Physics.Arcade.Group;
  private lizards!: Phaser.Physics.Arcade.Group;
  private lizardsLayer!: any;

  private playerLizardsCollider?: Phaser.Physics.Arcade.Collider;

  constructor() {
		super('game');
	}

  preload() {
    this.cursors = this.input.keyboard?.createCursorKeys();
  }

  create() {

    this.scene.run('game-ui');

    createCharacterAnims(this.anims);
    createLizardAnims(this.anims);
    createChestAnims(this.anims);

    const map = this.make.tilemap({key: 'dungeon'});
    const tileset = map.addTilesetImage('dungeon', 'tiles');

    map.createLayer('Ground', tileset!);

    this.knives = this.physics.add.group({
      classType: Phaser.Physics.Arcade.Image,
      maxSize: 3
    });

    // create character
    this.faune = this.add.faune(48, 64, 'faune');
    this.faune.setKnives(this.knives);
    this.faune.depth = 2;

    const wallsLayer: any = map.createLayer('Walls', tileset!);
    wallsLayer?.setCollisionByProperty({
      collides: true // this property is set in tiled
    });
    wallsLayer.depth = 1;

    const wallsBehindLayer: any = map.createLayer('WallsBack', tileset!);
    wallsBehindLayer?.setCollisionByProperty({
      collides: true // this property is set in tiled
    });
    wallsLayer.depth = 3;

    // chests
    const chests = this.physics.add.staticGroup({
      classType: Chest
    });
    const chestsLayer = map.getObjectLayer('Chests');
    chestsLayer?.objects.forEach((chestObj) => {
      chests.get(chestObj.x! + chestObj.width! * 0.5, chestObj.y! - chestObj.height! * 0.5, 'treasure');
    });

    map.createLayer('Decor', tileset!);

    // debugDraw(wallsLayer, this);

    // have the camera follow the character
    this.cameras.main.startFollow(this.faune, true);

    this.lizards = this.physics.add.group({
      classType: Lizard,
      createCallback: (go: any) => {
        const lizGo = go as Lizard;
        lizGo.body!.onCollide = true;
      }
    });

    this.lizardsLayer = map.getObjectLayer('Lizards');
    this.lizardsLayer?.objects.forEach((lizObj) => {
      this.lizards.get(lizObj.x! + lizObj.width! * 0.5, lizObj.y! + lizObj.height! * 0.5, 'lizard');
    });

    this.physics.add.collider(this.lizardsLayer, wallsLayer);
    this.physics.add.collider(this.faune, wallsLayer);
    this.playerLizardsCollider = this.physics.add.collider(this.lizards, wallsLayer);

    this.physics.add.collider(this.faune, wallsBehindLayer);
    this.physics.add.collider(this.lizards, wallsBehindLayer);

    this.physics.add.collider(this.faune, chests, this.handlePlayerChestCollision, undefined, this);

    this.physics.add.collider(this.knives, this.lizards, this.handleLizardKnifeCollision, undefined, this);
    this.physics.add.collider(this.knives, wallsLayer, this.handleKnifeWallCollision, undefined, this);
    this.physics.add.collider(this.knives, wallsBehindLayer, this.handleKnifeWallCollision, undefined, this);
    this.physics.add.collider(this.lizards, this.faune, this.handlePlayerLizardCollision, undefined, this);

  }

  private handlePlayerChestCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
    const chest = obj2 as Chest;
    this.faune.setChest(chest);
  }

  private handleKnifeWallCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
    this.knives.killAndHide(obj1);
  }

  private handleLizardKnifeCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
    this.knives.killAndHide(obj1);
    this.lizards.killAndHide(obj2);
    obj2.destroy();
  }

  private handlePlayerLizardCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {

    // obect 1 is faune
    const lizard  = obj2 as Lizard;

    const dx = this.faune.x - lizard.x;
    const dy = this.faune.y - lizard.y;

    const dir = new Phaser.Math.Vector2(dx, dy).normalize().scale(200);

    this.faune.handleDamage(dir);

    sceneEvents.emit('player-health-changed', this.faune.health);

    if (this.faune.health <= 0) {
      if (this.playerLizardsCollider!.world) {
        this.playerLizardsCollider?.destroy();
      }
    }

  }

  update(t: number, dt: number) {

    if (this.faune && this.cursors) {
      this.faune.update(this.cursors);
    }

  }

}
