import Phaser from "phaser";

export default class Chest extends Phaser.Physics.Arcade.Sprite {

  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame: string | number) {

    super(scene, x, y, texture, frame);

    // starting animation
    this.play('chest-closed');

  }

  open() {

    // if chest has been opened, do nothing
    if (this.anims.currentAnim!.key !== 'chest-closed') {
      console.log('return 0')
      return 0;
    }
    this.play('chest-open');

    const numCoins = Phaser.Math.Between(50, 200);

    return numCoins;

  }

}