{
	"textures": [
		{
			"image": "faune.png",
			"format": "RGBA8888",
			"size": {
				"w": 130,
				"h": 147
			},
			"scale": 1,
			"frames": [
				{
					"filename": "run-side-4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 7,
						"y": 6,
						"w": 17,
						"h": 22
					},
					"frame": {
						"x": 2,
						"y": 2,
						"w": 17,
						"h": 22
					}
				},
				{
					"filename": "walk-side-3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 7,
						"y": 6,
						"w": 17,
						"h": 22
					},
					"frame": {
						"x": 23,
						"y": 2,
						"w": 17,
						"h": 22
					}
				},
				{
					"filename": "run-down-1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 7,
						"y": 8,
						"w": 18,
						"h": 22
					},
					"frame": {
						"x": 2,
						"y": 28,
						"w": 18,
						"h": 22
					}
				},
				{
					"filename": "walk-down-3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 6,
						"w": 16,
						"h": 22
					},
					"frame": {
						"x": 24,
						"y": 28,
						"w": 16,
						"h": 22
					}
				},
				{
					"filename": "run-down-5.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 7,
						"y": 8,
						"w": 18,
						"h": 22
					},
					"frame": {
						"x": 44,
						"y": 2,
						"w": 18,
						"h": 22
					}
				},
				{
					"filename": "run-side-1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 5,
						"w": 18,
						"h": 22
					},
					"frame": {
						"x": 44,
						"y": 28,
						"w": 18,
						"h": 22
					}
				},
				{
					"filename": "run-side-2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 6,
						"w": 18,
						"h": 22
					},
					"frame": {
						"x": 2,
						"y": 54,
						"w": 18,
						"h": 22
					}
				},
				{
					"filename": "run-side-5.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 5,
						"w": 18,
						"h": 22
					},
					"frame": {
						"x": 24,
						"y": 54,
						"w": 18,
						"h": 22
					}
				},
				{
					"filename": "run-side-6.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 6,
						"w": 18,
						"h": 22
					},
					"frame": {
						"x": 46,
						"y": 54,
						"w": 18,
						"h": 22
					}
				},
				{
					"filename": "run-side-8.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 7,
						"y": 6,
						"w": 17,
						"h": 22
					},
					"frame": {
						"x": 66,
						"y": 2,
						"w": 17,
						"h": 22
					}
				},
				{
					"filename": "run-up-6.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 9,
						"w": 17,
						"h": 21
					},
					"frame": {
						"x": 66,
						"y": 28,
						"w": 17,
						"h": 21
					}
				},
				{
					"filename": "run-down-8.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 9,
						"w": 17,
						"h": 21
					},
					"frame": {
						"x": 2,
						"y": 80,
						"w": 17,
						"h": 21
					}
				},
				{
					"filename": "run-down-2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 9,
						"w": 16,
						"h": 21
					},
					"frame": {
						"x": 68,
						"y": 53,
						"w": 16,
						"h": 21
					}
				},
				{
					"filename": "faint-1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 7,
						"y": 7,
						"w": 17,
						"h": 21
					},
					"frame": {
						"x": 23,
						"y": 80,
						"w": 17,
						"h": 21
					}
				},
				{
					"filename": "run-side-3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 7,
						"w": 18,
						"h": 21
					},
					"frame": {
						"x": 44,
						"y": 80,
						"w": 18,
						"h": 21
					}
				},
				{
					"filename": "faint-2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 8,
						"w": 20,
						"h": 21
					},
					"frame": {
						"x": 66,
						"y": 80,
						"w": 20,
						"h": 21
					}
				},
				{
					"filename": "run-down-4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 7,
						"y": 9,
						"w": 17,
						"h": 21
					},
					"frame": {
						"x": 90,
						"y": 2,
						"w": 17,
						"h": 21
					}
				},
				{
					"filename": "faint-3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 9,
						"y": 14,
						"w": 21,
						"h": 15
					},
					"frame": {
						"x": 87,
						"y": 27,
						"w": 21,
						"h": 15
					}
				},
				{
					"filename": "run-side-7.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 7,
						"w": 18,
						"h": 21
					},
					"frame": {
						"x": 90,
						"y": 46,
						"w": 18,
						"h": 21
					}
				},
				{
					"filename": "run-down-6.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 9,
						"w": 16,
						"h": 21
					},
					"frame": {
						"x": 90,
						"y": 71,
						"w": 16,
						"h": 21
					}
				},
				{
					"filename": "run-up-1.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 6,
						"y": 8,
						"w": 19,
						"h": 21
					},
					"frame": {
						"x": 2,
						"y": 105,
						"w": 19,
						"h": 21
					}
				},
				{
					"filename": "run-up-2.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 7,
						"y": 9,
						"w": 17,
						"h": 21
					},
					"frame": {
						"x": 25,
						"y": 105,
						"w": 17,
						"h": 21
					}
				},
				{
					"filename": "run-up-5.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 7,
						"y": 8,
						"w": 19,
						"h": 21
					},
					"frame": {
						"x": 46,
						"y": 105,
						"w": 19,
						"h": 21
					}
				},
				{
					"filename": "walk-up-3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 6,
						"w": 16,
						"h": 21
					},
					"frame": {
						"x": 69,
						"y": 105,
						"w": 16,
						"h": 21
					}
				},
				{
					"filename": "run-up-4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 7,
						"y": 9,
						"w": 17,
						"h": 20
					},
					"frame": {
						"x": 90,
						"y": 96,
						"w": 17,
						"h": 20
					}
				},
				{
					"filename": "run-up-3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 10,
						"w": 16,
						"h": 20
					},
					"frame": {
						"x": 112,
						"y": 2,
						"w": 16,
						"h": 20
					}
				},
				{
					"filename": "run-down-7.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 10,
						"w": 17,
						"h": 20
					},
					"frame": {
						"x": 111,
						"y": 71,
						"w": 17,
						"h": 20
					}
				},
				{
					"filename": "run-up-7.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 10,
						"w": 17,
						"h": 20
					},
					"frame": {
						"x": 111,
						"y": 95,
						"w": 17,
						"h": 20
					}
				},
				{
					"filename": "run-up-8.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 9,
						"w": 17,
						"h": 20
					},
					"frame": {
						"x": 111,
						"y": 119,
						"w": 17,
						"h": 20
					}
				},
				{
					"filename": "run-down-3.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 8,
						"y": 10,
						"w": 16,
						"h": 20
					},
					"frame": {
						"x": 112,
						"y": 26,
						"w": 16,
						"h": 20
					}
				},
				{
					"filename": "faint-4.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 32,
						"h": 32
					},
					"spriteSourceSize": {
						"x": 9,
						"y": 14,
						"w": 20,
						"h": 15
					},
					"frame": {
						"x": 2,
						"y": 130,
						"w": 20,
						"h": 15
					}
				}
			]
		}
	],
	"meta": {
		"app": "http://free-tex-packer.com",
		"version": "0.6.5"
	}
}