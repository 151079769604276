import Phaser from 'phaser';
import dungeonTilesImg from '/public/tiles/dungeon/dungeon-tiles.png';
import dungeonTiles01Json from '/public/tiles/dungeon/dungeon-01.json';
import faunePNG from '/public/character/faune.png';
import fauneJSON from '/public/character/faune.json';
import liazrdPNG from '/public/enemies/lizard.png';
import lizardJSON from '/public/enemies/lizard.json';
import uiHeartEmptyPNG from '/public/ui/ui_heart_empty.png';
import uiHeartFullPNG from '/public/ui/ui_heart_full.png';
import knifePNG from '/public/weapons/weapon_knife.png';
import treasurePNG from '/public/items/treasure.png';
import treasureJSON from '/public/items/treasure.json';

// const chestOpenSound = require('/public/sounds/chest_open.wav');

// import chestOpenSound from '/public/sounds/chest_open.wav';
// import coinCollectedSound from '/public/sounds/coin_collected.wav';
// import fauneDamageSound from '/public/sounds/faune_damage_hit.wav';
// import fauneDeathSound from '/public/sounds/faune_death_sound.wav';
// import knifeWallHitSound from '/public/sounds/knife_wall_hit.wav';
// import lizardDeathSound from '/public/sounds/lizard_death.wav';
// import knifeThrowSound from '/public/sounds/knife_throw.wav';


export default class Preloader extends Phaser.Scene {

  constructor() {
    super('preloader');
  }

  preload() {

    this.load.image('tiles', dungeonTilesImg);
    this.load.tilemapTiledJSON('dungeon', dungeonTiles01Json);

    this.load.atlas('faune', faunePNG, fauneJSON);
    this.load.atlas('lizard', liazrdPNG, lizardJSON);
    this.load.atlas('treasure', treasurePNG, treasureJSON)

    this.load.image('ui-heart-empty', uiHeartEmptyPNG);
    this.load.image('ui-heart-full', uiHeartFullPNG);

    this.load.image('knife', knifePNG);

    // this.load.audio('chest-open-sound', chestOpenSoundUrl);
    // this.load.audio('coin-collected-sound', coinCollectedSound);
    // this.load.audio('faune-damage-sound', fauneDamageSound);
    // this.load.audio('faune-death-sound', fauneDeathSound);
    // this.load.audio('knife-wall-hit-sound', knifeWallHitSound);
    // this.load.audio('lizard-death-sound', lizardDeathSound);
    // this.load.audio('knife-throw-sound', knifeThrowSound);

  }

  create() {
    this.scene.start('game');
  }

}