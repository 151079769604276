import Phaser from 'phaser';

import { sceneEvents } from '../events/EventCenter';

export default class GameUI extends Phaser.Scene {

  private hearts!: Phaser.GameObjects.Group;

  constructor() {
    super({key: 'game-ui'});
  }

  create() {

    this.add.image(5, 27, 'treasure', 'coin_anim_f0.png');

    const coinsLabel = this.add.text(10, 22, '0', {
      fontSize: '12'
    });
    sceneEvents.on('player-coins-changed', (coins) => {
      coinsLabel.text = coins.toString();
    });

    this.hearts = this.add.group({
      classType: Phaser.GameObjects.Image
    });

    this.hearts.createMultiple({
      key: 'ui-heart-full',
      setXY: {
        x: 10,
        y: 10,
        stepX: 16
      },
      quantity: 3
    });

    // add event listener
    sceneEvents.on('player-health-changed', this.handlePLayerHealthChanged, this);

    // unbind events when the scene is shutdown
    // prevents duplicating events
    this.events.on(Phaser.Scenes.Events.SHUTDOWN, () => {
      sceneEvents.off('player-health-changed', this.handlePLayerHealthChanged);
      sceneEvents.off('player-coins-changed'); // remove all listeners for player-coins-changed (by not including function name)
    });


  }

  private handlePLayerHealthChanged(health: number) {

    // achildren are the hearts inside the group
    this.hearts.children.each((gameObj, index) => {

      const heart = gameObj as Phaser.GameObjects.Image;

      if (index < health) {
        heart.setTexture('ui-heart-full');
      } else {
        heart.setTexture('ui-heart-empty');
      }

    })

  }

}