{
  "frames": {
    "chest_empty_open_anim_f0.png": {
      "frame": {
        "x": 2,
        "y": 2,
        "w": 16,
        "h": 14
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 0,
        "y": 2,
        "w": 16,
        "h": 14
      },
      "sourceSize": {
        "w": 16,
        "h": 16
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "chest_empty_open_anim_f1.png": {
      "frame": {
        "x": 2,
        "y": 20,
        "w": 16,
        "h": 15
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 0,
        "y": 1,
        "w": 16,
        "h": 15
      },
      "sourceSize": {
        "w": 16,
        "h": 16
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "chest_empty_open_anim_f2.png": {
      "frame": {
        "x": 22,
        "y": 2,
        "w": 16,
        "h": 16
      },
      "rotated": false,
      "trimmed": false,
      "spriteSourceSize": {
        "x": 0,
        "y": 0,
        "w": 16,
        "h": 16
      },
      "sourceSize": {
        "w": 16,
        "h": 16
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "coin_anim_f0.png": {
      "frame": {
        "x": 22,
        "y": 22,
        "w": 6,
        "h": 7
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 1,
        "y": 1,
        "w": 6,
        "h": 7
      },
      "sourceSize": {
        "w": 8,
        "h": 8
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "coin_anim_f1.png": {
      "frame": {
        "x": 32,
        "y": 22,
        "w": 4,
        "h": 7
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 2,
        "y": 1,
        "w": 4,
        "h": 7
      },
      "sourceSize": {
        "w": 8,
        "h": 8
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "coin_anim_f2.png": {
      "frame": {
        "x": 2,
        "y": 39,
        "w": 2,
        "h": 7
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 3,
        "y": 1,
        "w": 2,
        "h": 7
      },
      "sourceSize": {
        "w": 8,
        "h": 8
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "coin_anim_f3.png": {
      "frame": {
        "x": 8,
        "y": 39,
        "w": 4,
        "h": 7
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 2,
        "y": 1,
        "w": 4,
        "h": 7
      },
      "sourceSize": {
        "w": 8,
        "h": 8
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    }
  },
  "meta": {
    "app": "http://free-tex-packer.com",
    "version": "0.6.5",
    "image": "treasure.png",
    "format": "RGBA8888",
    "size": {
      "w": 40,
      "h": 48
    },
    "scale": 1
  }
}