{
  "frames": {
    "lizard_m_hit_anim_f0.png": {
      "frame": {
        "x": 2,
        "y": 2,
        "w": 15,
        "h": 19
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 1,
        "y": 6,
        "w": 15,
        "h": 19
      },
      "sourceSize": {
        "w": 16,
        "h": 28
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "lizard_m_idle_anim_f0.png": {
      "frame": {
        "x": 21,
        "y": 2,
        "w": 15,
        "h": 19
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 1,
        "y": 9,
        "w": 15,
        "h": 19
      },
      "sourceSize": {
        "w": 16,
        "h": 28
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "lizard_m_idle_anim_f1.png": {
      "frame": {
        "x": 2,
        "y": 25,
        "w": 15,
        "h": 19
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 1,
        "y": 9,
        "w": 15,
        "h": 19
      },
      "sourceSize": {
        "w": 16,
        "h": 28
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "lizard_m_run_anim_f0.png": {
      "frame": {
        "x": 21,
        "y": 25,
        "w": 15,
        "h": 19
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 1,
        "y": 9,
        "w": 15,
        "h": 19
      },
      "sourceSize": {
        "w": 16,
        "h": 28
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "lizard_m_run_anim_f1.png": {
      "frame": {
        "x": 40,
        "y": 2,
        "w": 15,
        "h": 19
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 1,
        "y": 7,
        "w": 15,
        "h": 19
      },
      "sourceSize": {
        "w": 16,
        "h": 28
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "lizard_m_run_anim_f2.png": {
      "frame": {
        "x": 40,
        "y": 25,
        "w": 15,
        "h": 19
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 1,
        "y": 8,
        "w": 15,
        "h": 19
      },
      "sourceSize": {
        "w": 16,
        "h": 28
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "lizard_m_idle_anim_f3.png": {
      "frame": {
        "x": 2,
        "y": 48,
        "w": 15,
        "h": 18
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 1,
        "y": 10,
        "w": 15,
        "h": 18
      },
      "sourceSize": {
        "w": 16,
        "h": 28
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "lizard_m_run_anim_f3.png": {
      "frame": {
        "x": 21,
        "y": 48,
        "w": 15,
        "h": 17
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 1,
        "y": 11,
        "w": 15,
        "h": 17
      },
      "sourceSize": {
        "w": 16,
        "h": 28
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    },
    "lizard_m_idle_anim_f2.png": {
      "frame": {
        "x": 59,
        "y": 2,
        "w": 16,
        "h": 16
      },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": {
        "x": 0,
        "y": 12,
        "w": 16,
        "h": 16
      },
      "sourceSize": {
        "w": 16,
        "h": 28
      },
      "pivot": {
        "x": 0.5,
        "y": 0.5
      }
    }
  },
  "meta": {
    "app": "http://free-tex-packer.com",
    "version": "0.6.5",
    "image": "lizard.png",
    "format": "RGBA8888",
    "size": {
      "w": 77,
      "h": 68
    },
    "scale": 1
  }
}